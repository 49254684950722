import {request} from "@/util/request";

export function Dc_Add(data) {
    return request({
        url: '/Application.ashx/ProcessRequest',
        method: 'POST',
        data:data
    })
}



export function GetStation(params) {
    return request({
        url: '/Application.asmx/GetStation',
        method: 'GET',
        params
    })
}
