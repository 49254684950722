<template>
  <div>

    <van-form style="margin: 20px" @submit="onSubmit">

      <h2 style="text-align: center">人事报名</h2>
      <van-field
          readonly
          clickable
          name="picker"
          :value="Code"
          label="岗位编码"
          placeholder="请选择岗位编码"
          @click="showPickerCode = true"
      />
      <van-popup v-model="showPickerCode" position="bottom">
        <van-picker
            show-toolbar
            :columns="columnsCode"
            @confirm="onConfirmCode"
            @cancel="showPickerCode = false"
        />
      </van-popup>

      <van-field
          v-model="Positions"
          rows="1"
          autosize
          label="招聘岗位"
          type="textarea"
          disabled
      />

      <van-field
          v-model="Employers"
          rows="1"
          autosize
          label="用人单位"
          type="textarea"
          disabled
      />

      <van-field
          v-model="Station"
          rows="1"
          autosize
          label="岗位指标"
          type="textarea"
          disabled
      />

      <van-field
          v-model="Major"
          rows="1"
          autosize
          label="专业要求"
          type="textarea"
          disabled
      />

      <van-field
          v-model="Degree"
          rows="1"
          autosize
          label="学历要求"
          type="textarea"
          disabled
      />

      <van-field
          v-model="Rest"
          rows="1"
          autosize
          label="其他要求"
          type="textarea"
          disabled
      />


      <van-field
          v-model="Name"
          rows="1"
          autosize
          label="姓名"
          type="textarea"
          placeholder="请输入姓名"
          :rules="[{ required: true, message: '请输入姓名' }]"
      />
      <van-field
          v-model="IDCard"
          rows="1"
          autosize
          label="身份证号码"
          type="textarea"
          placeholder="请输入身份证号码"
          @blur="change()"
          :rules="[{ required: true, message: '请输入身份证号码' }]"
      />


      <van-field
          readonly
          clickable
          name="datetimeStart"
          :value="Birthday"
          label="出生年月"
          placeholder="点击选择时间"
          @click=" IDCardFlag!=false? showPickerBirthday = false:showPickerBirthday = true"
      />
      <van-popup v-model="showPickerBirthday" position="bottom">
        <van-datetime-picker
            :min-date="minDate"
            v-model="BirthdayDate"
            type="date"
            @confirm="onConfirmBirthday"
            @cancel="showPickerBirthday = false"
            :formatter="formatter"
        />
      </van-popup>


      <van-field
          readonly
          clickable
          name="picker"
          :value="Sex"
          label="性别"
          placeholder="请选择性别"
          @click="IDCardFlag!=false? showPickerSex = false:showPickerSex = true"
      />
      <van-popup v-model="showPickerSex" position="bottom">
        <van-picker
            show-toolbar
            :columns="columnsSex"
            @confirm="onConfirmSex"
            @cancel="showPickerSex = false"
        />
      </van-popup>

<!--      <van-field-->
<!--          v-model="Nation"-->
<!--          rows="1"-->
<!--          autosize-->
<!--          label="民族"-->
<!--          type="textarea"-->
<!--          placeholder="请输入民族"-->
<!--          :rules="[{ required: true, message: '请输入民族' }]"-->
<!--      />-->
<!--      <van-field-->
<!--          v-model="Native_Place"-->
<!--          rows="1"-->
<!--          autosize-->
<!--          label="籍贯"-->
<!--          type="textarea"-->
<!--          placeholder="请输入籍贯"-->
<!--          :rules="[{ required: true, message: '请输入籍贯' }]"-->
<!--      />-->
      <van-field
          v-model="Registered_Residence"
          rows="1"
          autosize
          label="户籍所在地/生源地"
          type="textarea"
          placeholder="请输入户籍所在地/生源地"
          :rules="[{ required: true, message: '请输入户籍所在地/生源地' }]"
      />

<!--      <van-field-->
<!--          v-model="Students"-->
<!--          rows="1"-->
<!--          autosize-->
<!--          label="生源地"-->
<!--          type="textarea"-->
<!--          placeholder="请输入生源地"-->
<!--          :rules="[{ required: true, message: '请输入生源地' }]"-->
<!--      />-->
      <van-field
          v-model="Political_Outlook"
          rows="1"
          autosize
          label="政治面貌"
          type="textarea"
          placeholder="请输入政治面貌"
          :rules="[{ required: true, message: '请输入政治面貌' }]"
      />
      <van-field
          readonly
          clickable
          name="picker"
          :value="Education"
          label="学历"
          placeholder="请选择学历"
          @click="showPickerEducation = true"
      />
      <van-popup v-model="showPickerEducation" position="bottom">
        <van-picker
            show-toolbar
            :columns="columnsEducation"
            @confirm="onConfirmEducation"
            @cancel="showPickerEducation = false"
        />
      </van-popup>

      <van-field
          readonly
          clickable
          name="picker"
          :value="Academic_Degree"
          label="学位"
          placeholder="请选择学位"
          @click="showPickerAcademicDegree = true"
      />
      <van-popup v-model="showPickerAcademicDegree" position="bottom">
        <van-picker
            show-toolbar
            :columns="columnsAcademicDegree"
            @confirm="onConfirmAcademicDegree"
            @cancel="showPickerAcademicDegree = false"
        />
      </van-popup>

      <van-field
          readonly
          clickable
          name="datetimeStart"
          :value="Graduation_Time"
          label="毕业时间"
          placeholder="点击选择时间"
          @click="showPickerGraduation_Time = true"
      />
      <van-popup v-model="showPickerGraduation_Time" position="bottom">
        <van-datetime-picker
            :min-date="minDate"
            v-model="GraduationDate"
            type="date"
            @confirm="onConfirmGraduation_Time"

            @cancel="showPickerGraduation_Time = false"
            :formatter="formatter"
        />
      </van-popup>



      <van-field
          v-model="Graduation_School"
          rows="1"
          autosize
          label="毕业学校及所学专业"
          type="textarea"
          placeholder="请输入毕业学校及所学专业"
          :rules="[{ required: true, message: '请输入毕业学校及所学专业' }]"
      />

      <van-field
          v-model="Phone"
          rows="1"
          autosize
          label="手机号码"
          type="textarea"
          placeholder="请输入手机号码"
          :rules="[{ required: true, message: '请输入正确手机号码' },{ pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号码格式错误！'}]"
      />


      <van-field
          v-model="Emergency_Contact"
          rows="1"
          autosize
          label="紧急联系人号码"
          type="textarea"
          placeholder="请输入手机号码"
          :rules="[{ required: true, message: '请输入紧急联系人号码' },{ pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号码格式错误！'}]"
      />

      <van-field
          v-model="Work_Unit"
          rows="1"
          autosize
          label="工作单位"
          type="textarea"
          placeholder="有工作经历填写"
      />

<!--      <van-field name="stepper" label="添加学习经历">-->
<!--        <template #input>-->
<!--          <van-stepper v-model="Num" @minus="remove()"  @plus="add()" />-->
<!--        </template>-->
<!--      </van-field>-->

<!--      <div v-for="(item,index) in customer" :key="index" >-->
<!--      <van-field-->
<!--          v-model="item.Do"-->
<!--          rows="1"-->
<!--          autosize-->
<!--          :label="`学习经历${(index + 1)}`"-->
<!--          type="textarea"-->
<!--          placeholder="请输入学习经历"-->
<!--          :rules="[{ required: true, message: '请输入学习经历' }]"-->
<!--      />-->

      <van-field
          readonly
          clickable
          name="picker"
          :value="IsTake"
          label="有无专业资格"
          placeholder="请选择有无专业资格"
          @click="showPickerIsTake = true"
      />
      <van-popup v-model="showPickerIsTake" position="bottom">
        <van-picker
            show-toolbar
            :columns="columnsIsTake"
            @confirm="onConfirmIsTake"
            @cancel="showPickerIsTake= false"
        />
      </van-popup>


      <van-field
          v-model="Take"
          rows="1"
          autosize
          label="专业资格"
          type="textarea"
          placeholder="请输入专业资格"
          v-show="TakeFlag"
          :rules="[{ required: TakeFlag, message: '请输入专业经历' }]"
      />
<!--      </div>-->
      <van-field
          readonly
          clickable
          name="picker"
          :value="Graduate"
          label="是否应届生"
          placeholder="请选择是否应届生"
          @click="showPickerGraduate = true"
      />
      <van-popup v-model="showPickerGraduate" position="bottom">
        <van-picker
            show-toolbar
            :columns="columnsGraduate"
            @confirm="onConfirmGraduate"
            @cancel="showPickerGraduate = false"
        />
      </van-popup>

      <van-field
          v-model="Remarks"
          rows="1"
          autosize
          label="备注"
          type="textarea"
          placeholder="请输入备注"
      />
      <van-field name="checkbox" label="注">
        <template #input>
          <van-checkbox v-model="checked" :onchange="changeChecked()" shape="square"> <p style="color: red">上述填写内容真实完整。如有不实，本人愿承担一切责任。</p></van-checkbox>

        </template>

      </van-field>

      <div style="margin: 16px;">
        <van-button round block :disabled="BtnFlag" type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>



import {Toast} from "vant";
import moment from "moment-timezone";
import {Dc_Add, GetStation} from "../api/Application";

export default {
  name: 'Home',
  data(){
    return{
        Num:1,
        customer: [{
          Do: '',
          Take:''
        }],
      Code:'',
      columnsCode:[],
      showPickerCode:false,
      Positions:'',
      Employers:'',
      Station:'',
      Major:'',
      Degree:'',
      Rest:'',
      Name:'',
      IDCard:'',
      IDCardFlag:false,
      Birthday:'',
      showPickerBirthday:false,
      BirthdayDate:new Date(),
      Sex:'',
      Nation:'',
      Native_Place:'',
      Registered_Residence:'',
      Students:'',
      Political_Outlook:'',
      Education:'',
      Academic_Degree:'',
      Graduation_Time:'',
      showPickerGraduation_Time:false,
      GraduationDate:new Date(),
      Graduation_School:'',
      Phone:'',
      Emergency_Contact:'',
      Work_Unit:'',
      IsTake:'',
      columnsIsTake:['有','无'],
      showPickerIsTake:false,
      TakeFlag:false,
      Take:'',
      Graduate:'',
      Remarks:'',
      columnsSex:['男','女'],
      showPickerSex:false,
      columnsEducation:['中专','大专','本科','研究生'],
      showPickerEducation:false,
      columnsAcademicDegree:['无','学士学位','硕士学位','博士及以上学位'],
      showPickerAcademicDegree:false,
      columnsGraduate:['是','否','择业期'],
      showPickerGraduate:false,
      checked:false,
      BtnFlag:true,
      minDate:new Date(1970, 0, 1)
    };
  },

created() {
this.fetchData()
},
  mounted() {



  },
  methods: {
    async onSubmit(){
      if(this.Code==''){
        Toast.fail('请选择岗位编码！')
        return
      }
     if (!this.IDCardFlag){
       Toast.fail('身份证输入有误，请核对后再提交！')
        return
     }
     if (this.Education==''){
       Toast.fail('请选择学历！')
       return
     }
      if (this.Academic_Degree==''){
        Toast.fail('请选择学位！')
        return
      }
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '正在提交...',
        forbidClick: true,
      });
      var obj = {
        Code:this.Code,
        Name:this.Name,
        IDCard:this.IDCard,
        Birthday:this.Birthday,
        Sex:this.Sex,
        //Nation:this.Nation,
        //Native_Place:this.Native_Place,
        Registered_Residence:this.Registered_Residence,
        //Students:this.Students,
        Political_Outlook:this.Political_Outlook,
        Education:this.Education,
        Academic_Degree:this.Academic_Degree,
        Graduation_Time:this.Graduation_Time,
        Graduation_School:this.Graduation_School,
        Phone:this.Phone,
        Emergency_Contact:this.Emergency_Contact,
        Work_Unit:this.Work_Unit,
        IsTake:this.IsTake,
        Take:this.Take,
        Graduate:this.Graduate,
        Remarks:this.Remarks
      }
      console.log(obj)
      const {data} = await Dc_Add(obj);
      if (data.code === 200) {
        Toast.clear();
        Toast.success('提交成功！');
        await this.$router.push({
          path: '/index'
        })
      }else if(data.code === 201){
        Toast.clear();
        Toast.success('当前身份证报名用户已存在，无需二次报名！');
      } else {
        Toast.clear();
        Toast.success('提交失败！');
      }
    },
    remove() {
      if (this.Num>=2){
        this.customer.pop({
          Do: '',
          Take:''
        })
      }
    },
    add() {
      this.Num=this.Num+1
      console.log(this.customer)
      this.customer.push({
        Do: '',
        Take:''
      })
      console.log(this.customer)
    },
    onConfirmCode (value){
      console.log(value)
       this.Code = value.text;
      this.Positions= value.Positions;
          this.Employers= value.Employers;
          this.Station= value.Station;
          this.Major= value.Major;
          this.Degree= value.Degree;
          this.Rest= value.Rest;


       this.showPickerCode = false;

    },
    onConfirmSex(value){
      this.Sex = value;
      this.showPickerSex = false;
    },
    onConfirmEducation(value){
      this.Education = value;
      this.showPickerEducation = false;
    },
    onConfirmAcademicDegree(value){
      this.Academic_Degree = value;
      this.showPickerAcademicDegree = false;
    },

    onConfirmIsTake(value){
      this.IsTake= value;
      if (this.IsTake=='有'){
        this.TakeFlag=true
      }else if (this.IsTake=='无'){
        this.TakeFlag=false
        this.Take=''
      }

      this.showPickerIsTake=false;
    },
    onConfirmGraduate(value){
      this.Graduate = value;
      this.showPickerGraduate = false;
    },
    change(){
      this.IDCardFlag=false
      var city={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江 ",
        31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北 ",
        43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏 ",
        61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外 "};
      var tip = "";
      var pass= true;
      var patt1=new RegExp("(^[1-9]\\d{5}(18|19|([23]\\d))\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$)|(^[1-9]\\d{5}\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{2}$)");
      if (this.IDCard == '') {
        return
      } else if (!patt1.test(this.IDCard)) {
        Toast.fail({ message: '身份证号格式错误' })
        return
      } else if (!city[this.IDCard.substr(0, 2)]) {
        Toast.fail({ message: '地址编码错误' })
        return
      } else {//18位身份证需要验证最后一位校验位
        if (this.IDCard.length == 18) {
          let  IDCard = this.IDCard.split('');
          //∑(ai×Wi) (mod 11)//加权因子
          var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
          //校验位
          var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
          var sum = 0;
          var ai = 0;
          var wi = 0;
          for (var i = 0; i < 17; i++){
            ai = IDCard[i];
            wi = factor[i];
            sum += ai * wi;
          } var last = parity[sum % 11];
          if (parity[sum % 11] != IDCard[17]) {
            Toast.fail({ message: '身份证号码错误' })
            return
          }
        }
      }
      if (pass) {
        this.IDCardFlag=true
      }
      //判断性别
      var date = new Date();
      //判断出生日期
      if (this.IDCard.length == 18 || this.IDCard.length == 15) {
        if (this.IDCard.length > 15) {
          if (this.IDCard.length== 18) {
            var last = this.IDCard.substr(16,1);
            if (last % 2 != "0") {
              this.Sex= '男'
            } else {
              this.Sex= '女'
            }
            var year = this.IDCard.substring(6, 14);
            this.Birthday=year
          }
        }
        else {
          if (this.IDCard.length  == 15) {
            var last = this.IDCard.substr(14,1);
            if (last % 2 != "0") {
              this.Sex = '男'
            } else {
              this.Sex= '女'
            }
            var year = "19" + this.IDCard.substring(6, 12);
            this.Birthday=year
          }
        }
      } else {
        Toast.success('请填写正确户主的15位或18位身份证号！');
      }
    },
    onConfirmBirthday(time){

      this.Birthday = moment(time).format('yyyyMMDD');
      this.showPickerBirthday = false;

    },
    onConfirmGraduation_Time(time){

      this.Graduation_Time = moment(time).format('yyyy-MM-DD');
      this.showPickerGraduation_Time = false;

    },
    //格式化时间
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    changeChecked(){
      if (this.checked){
          this.BtnFlag=false
      }else {
        this.BtnFlag=true
      }
    },
    async fetchData(){
      const {data} = await GetStation({token: this.token, ID: this.ID})
      if (data.code==200){
        this.columnsCode=data.columnsCode
      }
    }
  }
}
</script>
