/**
 * @description 导出网络配置
 **/
module.exports = {
    baseURL: '/api',
    //baseURL: 'http://10.60.3.112',
    //baseURL: 'http://113.31.115.78:8089',

    // 配后端数据的接收方式
    contentType: 'application/json;charset=UTF-8',
    // 最长请求时间
    requestTimeout: 100000,
    // 操作正常code，支持String、Array、int多种类型
    successCode: [200, 0, '200', '0'],
    // 数据状态的字段名称
    statusName: 'code',
    // 状态信息的字段名称
    messageName: 'msg',
}
