import Vue from 'vue'
import axios from 'axios'
import {
    baseURL,
    contentType,
    messageName,
    requestTimeout,
    statusName,
} from '@/config/net.config'

/**
 * @description axios初始化
 */


export function request(config) {
    console.log(baseURL)
    const instance = axios.create({
        baseURL : baseURL,
        timeout: requestTimeout,
        /*headers: {
          'Content-Type': contentType,
        },*/
    })

    instance.interceptors.request.use(config => {
        return config
    }, err => {

    })

    instance.interceptors.response.use(res => {
        console.log(res)
        return res
    },err => {
        console.log(err)
    })

    return instance(config)
}

